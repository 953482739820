<template>
	<div class="" v-loading="loading_load">
		<!-- <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
		</el-button> -->
		<!-- <el-descriptions title="">
			<el-descriptions-item :label="$t('i18nn_14b9b51812811e15')">{{tableData.totalFee}}</el-descriptions-item>
		</el-descriptions> -->

		<el-card shadow="never" style="margin-bottom: 5px;">
			<div slot="header" class="">
				<div>
					<!-- 费用明细 -->
					<!-- <el-descriptions title="">
						<el-descriptions-item :label="$t('i18nn_14b9b51812811e15')">{{tableData.totalFee}}</el-descriptions-item>
					</el-descriptions> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>

					<span>{{$t('i18nn_14b9b51812811e15')}}</span>:
					<span>
						{{tableData.totalFee}}
					</span>

				</div>
			</div>

			<el-table ref="multipleTable" :data="tableData.feeRecrod" stripe :border="true"
				:max-height="$store.state.tableMaxHeight5" style="width: 100%" size="small">
				<el-table-column type="index" fixed="left" width="50" align="center"
					:label="$t('7b1c2b1adc920d9c')"></el-table-column>
				
				<el-table-column prop="opNo" :label="$t('i18nn_4b5536ea9955714b')">
					<template slot-scope="scope">
						<!-- <router-link :to="{name:'WhFinanceDetList',query:{opNo:scope.row.opNo}}">{{scope.row.opNo}}</router-link> -->
						<span>{{ scope.row.opNo }}</span>
						<!-- <div @click="toDet(scope.row)"> -->
						<!-- {{scope.row.plNo}} -->
						<!-- </div> -->
					</template>
				</el-table-column>
				
				<el-table-column prop="whNo" :label="$t('5acbec83efb27445')"></el-table-column>
				<el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')"></el-table-column>
				<el-table-column prop="relationNo" :label="$t('i18nn_9168144190f66f5d')"></el-table-column>
				<el-table-column prop="sysOrderNo" :label="$t('i18nn_52a8c38184d84581')"></el-table-column>
				
				<el-table-column prop="opStatus" :label="$t('i18nn_15a021996904c423')">
					<template slot-scope="scope">
						<div>
							<el-tag v-if="'10' == scope.row.opStatus">{{ scope.row.opStatusName }}</el-tag>
							<el-tag type="warning" v-else-if="'20' == scope.row.opStatus">{{ scope.row.opStatusName }}</el-tag>
							<el-tag type="info" v-else>{{ scope.row.opStatusName }}</el-tag>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="bizTypeName" :label="$t('184333c81babf2f1')">
					<template slot-scope="scope">
						<div>
							{{ $Utils.i18nKeyText(scope.row,'bizTypeName') }}
						</div>
					</template>
				</el-table-column>

				<!-- isBillName
				opStatusName
				opTypeName
				relationNo
				sysOrderNo
				whNo
				sysOrderNo -->
				<!-- <el-table-column prop="isBillName" :label="$t('i18nn_600c6fad90d0d7b1')"></el-table-column> -->
				<!-- <el-table-column prop="opStatusName" :label="$t('i18nn_15a021996904c423')"></el-table-column> -->
				<el-table-column prop="opTypeName" :label="$t('i18nn_82b5bd967d494be3')"></el-table-column>
				<el-table-column prop="isBillName" :label="$t('i18nn_06dd7cb65641390b')">
					<template slot-scope="scope">
						<div>
							<el-tag v-if="'10' == scope.row.isBill">{{ scope.row.isBillName }}</el-tag>
							<el-tag type="warning" v-else-if="'20' == scope.row.isBill">{{ scope.row.isBillName }}</el-tag>
							<el-tag type="info" v-else>{{ scope.row.isBillName }}</el-tag>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="describe" :label="$t('i18nn_ab6ea90b9164b20a')"
					show-overflow-tooltip></el-table-column>
					
				<!-- <el-table-column prop="whNo" :label="$t('c944a6686d996ab3')"></el-table-column> -->
				<!-- <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column> -->
				<!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->
				<el-table-column prop="updateTime" :label="$t('2594059036dfede5')" width="200">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('93f5ca01b006206c')}}</span>:
									<span>{{scope.row.opTime}}</span>
								</li>

								<li>
									<span>{{$t('i18nn_e1911e9360047dcb')}}</span>:
									<span>{{scope.row.updateTime}}</span>
								</li>
								<li>
									<span>{{$t('i18nn_e8564657bbe9ca53')}}</span>:
									<span>{{scope.row.createTime}}</span>
								</li>
							</ul>

						</div>
					</template>
				</el-table-column>

				<!-- createTime -->

				<el-table-column prop="recordAmtDtoList" :label="$t('i18nn_6afbe4c6dcf87a6c')" width="400">
					<template slot-scope="scope">
						<div v-if="scope.row.recordAmtDtoList">
							<el-table :data="scope.row.recordAmtDtoList" stripe :border="true" style="width: 100%" size="small">
								<!-- <el-table-column prop="bizType" :label="$t('i18nn_9666c59aedbfa70e')"></el-table-column> -->

								<!-- feeTypeName
								feeAmt
								calcJson
								opType
								relationNo -->
								<el-table-column prop="feeTypeName" :label="$t('46c3f6e0f657e7a3')"></el-table-column>
								<el-table-column prop="feeAmt" :label="$t('i18nn_16658a855f07d171')"></el-table-column>
								<!-- <el-table-column prop="calcJson" :label="$t('i18nn_fa4b8c97858f967d')"></el-table-column> -->
								<!-- <el-table-column prop="calcJson" :label="$t('i18nn_6ffc5b58796093bb')">
									<template slot-scope="scope2">
										<el-popover placement="top" trigger="hover">
											<div class="pre-text">{{ scope2.row.calcJson }}</div>
											<span class="over_ellipsis" slot="reference">{{scope2.row.calcJson}}</span>
										</el-popover>
									</template>
								</el-table-column> -->
								<!-- <el-table-column prop="remark" :label="$t('15b3627faddccb1d')"></el-table-column> -->
								<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
									<template slot-scope="scope">
										<el-popover placement="top" trigger="hover">
											<div class="pre-text">{{ scope.row.remark }}</div>
											<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
										</el-popover>
									</template>
								</el-table-column>
								<!-- <el-table-column prop="opType" :label="'操作类型TYPE'"></el-table-column> -->
								<!-- <el-table-column prop="relationNo" :label="$t('i18nn_9168144190f66f5d')"></el-table-column> -->
								<el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column>
							</el-table>
						</div>
					</template>
				</el-table-column>


				<!-- <el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column> -->

			</el-table>
		</el-card>
	</div>
</template>
<script>
	export default {
		props: {
			id: {
				default: function() {
					return '';
				},
				type: String
			},
		},
		components: {

		},
		data() {
			return {
				loading_load: false,
				tableData: {},
			};
		},
		watch: {
			id: function(newVal, oldVal) {
				this.initData();
			}
		},
		activated() {
			// 
		},
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				this.tableData = {};
				if (this.id) {
					this.getPageData(this.id)
				}
			},
			//请求分页数据
			getPageData(id) {
				this.loading_load = true;
				this.$http
					.get(this.$urlConfig.WhFeeModleQueryById + "/" + id + "/fees", {})
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code) {
							this.tableData = data.data;
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('81d48fdc2158d55d'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('bf360c235e89e2eb'));
						this.loading_load = false;
					});
			},
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>